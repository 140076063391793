import React from 'react'
import { NavLink } from 'react-router-dom'

const NotFound = () => {
    return (
        <section className="bg-white dark:bg-gray-900 min-h-screen flex justify-center items-center">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-6xl tracking-tight font-extrabold font-gilroyExtraBold lg:text-7xl text-primary ">Not Found</h1>
                    <p className="mb-2 text-lg font-light font-gilroyRegular text-gray-500 dark:text-gray-400">Whoops! That page doesn’t exist.</p>
                    <NavLink to="/" className="inline-flex  text-white bg-primary  focus:outline-none ring-0 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4 font-gilroyRegular">Back to Homepage</NavLink>
                </div>
            </div>
        </section>
    )
}

export default NotFound
